import { ReactNode } from 'react';
import { useStore } from 'effector-react';
import * as A from 'fp-ts/es6/Array';
import { pipe } from 'fp-ts/es6/function';
import * as O from 'fp-ts/es6/Option';
import { DesignFeaturesStore } from '../effector/designFeatures';
import { DesignFeature, DesignFeatureName } from '../types/designFeature';

type GetDesignFeatureParameters<R> = {
    featureName: DesignFeatureName;
    getter: (feature: DesignFeature) => R;
    fallback: R;
};

type GetDesignFeatureCaption = (featureName: DesignFeatureName, fallback: string) => string;

type IsDesignFeatureVisible = (featureName: DesignFeatureName) => boolean;
type IsDesignFeaturesVisible = (featureNames: DesignFeatureName[]) => boolean;

type DesignFeatureRenderer<T extends keyof DesignFeature> = (feature: Pick<DesignFeature, T>) => ReactNode;

type RenderDesignFeature = (name: DesignFeatureName, render: DesignFeatureRenderer<'name'>) => ReactNode;

type DisplayDesignFeature = (
    name: DesignFeatureName,
    caption: string,
    render: DesignFeatureRenderer<'name' | 'caption'>
) => ReactNode;

export const useDesignFeatures = (): {
    getDesignFeatureCaption: GetDesignFeatureCaption;
    isDesignFeatureVisible: IsDesignFeatureVisible;
    isDesignFeaturesVisible: IsDesignFeaturesVisible;
    renderDesignFeature: RenderDesignFeature;
    displayDesignFeature: DisplayDesignFeature;
} => {
    const designFeatures = useStore(DesignFeaturesStore);

    const getDesignFeature = <R>({ featureName, getter, fallback }: GetDesignFeatureParameters<R>): R =>
        pipe(
            designFeatures,
            A.findFirst(({ name }) => name === featureName),
            O.fold(() => fallback, getter)
        );

    const getDesignFeatureCaption: GetDesignFeatureCaption = (featureName, fallback) =>
        getDesignFeature({
            featureName,
            getter: (f) => f.caption,
            fallback,
        });

    const isDesignFeatureVisible: IsDesignFeatureVisible = (featureName) =>
        getDesignFeature({
            featureName,
            getter: (f) => f.visible,
            fallback: true,
        });

    return {
        getDesignFeatureCaption,
        isDesignFeatureVisible,
        isDesignFeaturesVisible: (featureNames) => featureNames.some(isDesignFeatureVisible),
        renderDesignFeature: (name, render) => (isDesignFeatureVisible(name) ? render({ name }) : null),
        displayDesignFeature: (name, fallback, render) =>
            isDesignFeatureVisible(name)
                ? render({
                      name,
                      caption: getDesignFeatureCaption(name, fallback),
                  })
                : null,
    };
};
